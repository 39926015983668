/* @EVENTS */
.events-column-titles {
  display: none;
}

.event-item {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  position: relative;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
  color: $neutral-100;
  font-weight: 600;
  font-size: 14px;
  background: $neutral-0;
  align-items: center;
  border: 1px solid $primary-40;
  box-shadow: $box-shadow-tiny;
}

.event-item--red {
  background: $neutral-0;
}

.event-item__status {
  text-align: right;
}

.event-item__type {
  font-size: 12px;
  letter-spacing: 2px;
  color: $neutral-70;
  display: inline-block;
  padding: 0 8px;
  text-align:center;
  border-radius: 2px;
  background: $neutral-0;
  border: 1px solid $primary-40;
  text-transform: uppercase;
  position: relative;
  max-width: 120px;
  height: 26px;
  color: $neutral-0;
}

.event-item__type--red {
  background-color: #E45260;
  border-color: #D24150;
}

.event-item__type--green {
  background-color:#9ACC64;
  border-color: #87B84E;

}

.event-item__type--blue {
  background-color:#4CB8DF;
  border-color: #39A7D1;
}

.event-item__type--purple {
  background-color: #A58CE4;
  border-color: #9076D3;
}

.event-item__type--orange {
  background-color: #F16A4F;
  border-color: #E0543C;
}


@media screen and (min-width: 600px) {

  .events-column-titles {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 3fr 4fr 4fr 2fr 4fr 3fr 24px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .event-item {
    grid-template-columns: 3fr 4fr 4fr 2fr 4fr 3fr 24px;
  }

  .event-item__checkbox {
    text-align: right;
  }

  .event-column-title--checkbox {
    text-align: right;
    margin-right: 10px;
  }
}