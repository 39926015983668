/* @DEVICES */
.devices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 600px) {
  .devices {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1024px) {
  .devices {
    grid-gap: 32px;
  }
}

@media screen and (min-width: 1920px) {
  .devices {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.device-tools__item {
  cursor: pointer;
}

.device-card {
  transition: 0.3s;
  position: relative;
  display: block;
  border: 2px solid $primary-40;
  border-radius: 4px;
  background: $neutral-0;
  box-shadow: $box-shadow;
  text-decoration: none;
}

.device-card:hover {
  border: 2px solid $primary-70;
  box-shadow: $box-shadow-selected;
}

.device-card__header {
  display: grid;
  grid-template-columns: auto 40px 40px;
  grid-gap: 8px;
  padding: 8px;
}

.device-card__title {
  font-weight: 600;
  font-size: 24px;
  color: $primary-90;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  padding: 8px;
  text-decoration: none;
}

.device-card__header-button {
  display: block;
  width: 40px;
  height: 40px;
  background: $primary-70;
  color: $neutral-0;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.device-card__header-button.header-button--inactive {
  background: $primary-20;
  cursor: default;
}

.device-card__body {
  padding: 0 8px 8px;
  text-align: center;
}

.device-card.device-card--active {
  background: $neutral-0;


  .device-card__device-title {
    color: $primary-90;
  }

  .device-card__device-firmware {
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.device-card__body {
  padding: 16px;
  padding-bottom: 32px;
}

.device-card__button {
  display: block;
}

.device-card__device-icon {
  padding: 16px;
}

.device-card__device-title {
  color: $neutral-70;
  font-weight: 700;
}

.device-card__device-serial {
  font-size: 14px;
}

.device-card__device-location {
  font-size: 14px;
  color: $neutral-70;
}

.device-card__device-location a {
  text-decoration: none;
}

.app-icon--device-location {
  position: relative;
  bottom: -2px;
}

.device-card__manage {
  border-top: 1px solid $primary-40;
  padding: 8px;
  text-align:center;
  text-transform: uppercase;
  font-weight: 600;
  color: $primary-70;
  display: block;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
}

.device-update {
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  padding: 88px 48px;
}

.device-update__icon {
  margin-bottom: 24px;
}