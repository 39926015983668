/* NOTIFICATIONS */
.notifications__header {
  display: none;
}

.notifications__row {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  margin-bottom: 32px;
  border: 1px solid $primary-40;
  background: $neutral-0;
  box-shadow: $box-shadow;
  border-radius: 4px;
  padding: 64px 8px 0;
  position: relative;
}

.notifications__row-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.notifications__row-data {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  position: relative;
  margin-bottom: 8px;
}

.form .form-group .notifications__row  input[type=text] {
  height: 40px;
  box-shadow: none;
  padding: 0 8px;
}

@media screen and (min-width: 768px) {
  .notifications__header {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 2fr 1fr 100px 100px 40px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .notifications__row {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: auto 40px;
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    margin-bottom: 0;
  }

  .notifications__row-button {
    position: relative;
    top: 0;
    right: 0;
  }

  .notifications__row-data {
    grid-template-columns: 2fr 1fr 100px 100px;
  }
}






.rnc__notification-container--bottom-right {
  bottom: 70px;
}

@media screen and (min-width: 768px) {
  .rnc__notification-container--bottom-right {
    bottom: 20px;
  }
}