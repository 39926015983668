/* @NAVIGATION */
.navigation {
  display: none;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: $neutral-0;
  border-right: 1px solid $primary-40;
  box-shadow: $navigation-shadow;
}

.navigation-item {
  position: relative;
}

.navigation__menu {
  min-height: 100vh;
  position: relative;
}

@media screen and (min-width: 768px) {
  .navigation {
    display: block;
    min-height: 1050px;
  }
}

@media screen and (min-width: 1920px) {
  .navigation__main {
    padding-top: 16px;
  }
}

.navigation__logo {
  border-bottom: 1px solid $primary-40;
  display: block;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
}

.navigation__logo--vertical {
  background-image: url(../img/magicbox-logo-navigation-vertical.svg);
  background-size: 60px 60px;
}

.navigation__logo--horizontal {
  background-image: url(../img/magicbox-logo-navigation-horizontal.svg);
  background-position: 40px 4px;
  display: none;
}

@media screen and (min-width: 1920px) {
  .navigation__logo--vertical {
    display: none;
  }
  .navigation__logo--horizontal {
    display: block;
  }
}

.navigation-item__link {
  display: block;
  padding: 32px 0;
  text-align: center;
  text-decoration: none;
}

.navigation-item__title {
  color: $neutral-70;
  position: relative;
  top: 10px;
}

.navigation-item__link--current .navigation-item__title {
  color: $neutral-100;
}

.navigation-item__title {
  font-weight: 700;
}

@media screen and (min-width: 1920px) {
  .navigation-item__link {
    padding: 16px 0;
    height: 88px;
    display: grid;
    grid-template-columns: 8px auto;
    grid-column-gap: 40px;
  }

  .navigation-item__hightlight {
    background: $neutral-0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  /*
  .navigation-item__link--current .navigation-item__hightlight {
    background: $navigation-active-background;
  }
  */

  .navigation-item__navigation-item {
    display: grid;
    grid-template-columns: 48px auto;
    grid-column-gap: 32px;
    align-items: center;
  }

  .navigation-item__title {
    font-size: 24px;
    text-align: left;
    position: relative;
    top: 2px;
  }
}


.navigation__tools {
  width: 100%;
}

@media screen and (min-width: 1920px) {
  .navigation__tools {
    padding-bottom: 16px;
  }
}

@media screen and (min-height: 1100px) {
  .navigation__tools {
    position: absolute;
    bottom: 45px;
  }
}


.navigation__version {
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid $primary-40;
}

@media screen and (min-height: 1100px) {
  .navigation__version {
    position: absolute;
    bottom: 0;
  }
}

.navigation-item__item-count {
  display: block;
  position: absolute;
  border: 1px solid black;
  background: black;
  color: $neutral-0;
  line-height: 32px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  top: 24px;
  left: 40px;
  text-shadow: 0px 0px 2px rgba(66, 68, 90, 1);
}


.navigation-item__item-count--alarm {
  background: $secondary-50;
  border-color: $secondary-60;
}