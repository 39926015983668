.image-selector {
  width: 100%;
}

.image-selector__clear {
  content: "";
  clear: both;
  display: table;
}

.image-selector__image-wrapper {
  float: left;
  margin: 0 16px 16px 0;
  transition: all 0.2s ease;
  width: 148px;
  height: 148px;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  border: 2px solid $primary-40;
  border-radius: 4px;
  padding: 8px;
  background: $neutral-0;
  box-shadow: $box-shadow;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
}

@media screen and (min-width: 600px) {
  .image-selector__image-wrapper {
    margin: 0 24px 24px 0;
  }
}

.image-selector__image-wrapper:hover {
  border: 3px solid $primary-70;
  cursor: pointer;
}

.image-selector__image--selected {
  border: 3px solid $primary-70;
  box-shadow: $box-shadow-selected;
}