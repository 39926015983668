/* @TABLE */
.table-header__titles {
    display: none;
}

.table-body__row {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  position: relative;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px;
  color: $neutral-100;
  font-weight: 600;
  font-size: 14px;
  background: $neutral-0;
  align-items: center;
  border: 1px solid $primary-40;
  box-shadow: $box-shadow-tiny;
}

@media screen and (min-width: 600px) {
  .table-header__titles {
    display: block;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;
  }

  .table-body__row {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;
  }

  .table--col-2 {
    grid-template-columns: 1fr 1fr;
  }

  .table--col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .table--col-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

}