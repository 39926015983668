/* @SCENARIO */
.action__wrapper {
  margin-bottom: 16px;
}

.action__header {
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: auto 24px;
  grid-gap: 16px;
}

.action__body {
  margin-bottom: 64px;
}


.action__row {
  position: relative;
  display: block;
  border: 2px solid $primary-20;
  background: $neutral-0;
  border-radius: 4px;
  padding: 16px;
  text-decoration: none;
  margin-bottom: 32px;
}


.action-row__item {

  margin-bottom: 8px;

  .action-row__label {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .action-row__input,
  .action-row__time,
  .action-row__select {
    border: 1px solid $primary-40;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;
    background: $neutral-0;
    box-shadow: $box-shadow-tiny;
    backdrop-filter: blur(4px);
    width: 100%;
    color: $neutral-100;
    padding: 0 16px 0 48px;
    line-height: 48px;
    height: 48px;
    font-size: 16px;
    transition: all 0.5s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .action-row__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='16px' height='10px' viewBox='0 0 24 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><polygon points='12 14.75 0 2.75 2.15 0.6 12 10.5 21.85 0.65 24 2.8'></polygon></svg>");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 18px;
  }

  .day-selection {
    margin-bottom: 16px;
  }

  .day-selection__day {
    display: inline-block;
    width: 42px;
    height: 42px;
    border: 2px solid $primary-40;
    box-shadow: $form-shadow;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    line-height: 40px;
    color: $neutral-70;
    font-weight: 600;
    background: $neutral-0;
  }

  .day-selection__day:hover {
    cursor: pointer;
    border: 2px solid $primary-70;
  }
  
  .day-selection__day--selected {
    border: 2px solid $primary-70;
    background: $neutral-0;
    color: $neutral-100;
  }

}


.action-row__item:last-child {
  margin-bottom: 0;
}




.scenario-form {

  .scenario-form__group {
    margin-bottom: 16px;
    position: relative;
  }

  .scenario-form__group:last-child {
    margin-bottom: 0;
  }
  
  .scenario-form__group label {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }


  .scenario-form__group input[type=text],
  .scenario-form__group input[type=email],
  .scenario-form__group input[type=password],
  .scenario-form__group input[type=time],
  .scenario-form__group select {
    border: 1px solid $primary-40;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;
    background: $neutral-0;
    box-shadow: $box-shadow-tiny;
    backdrop-filter: blur(4px);
    width: 100%;
    color: $neutral-100;
    padding: 0 16px 0 48px;
    line-height: 48px;
    height: 48px;
    font-size: 16px;
    transition: all 0.5s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .scenario-form__group input:disabled,
  .scenario-form__group input:disabled,
  .scenario-form__group input:disabled,
  .scenario-form__group input:disabled,
  .scenario-form__group select:disabled {
    background: $primary-20;
    color: $primary-40;
  }
  
  .scenario-form__group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='16px' height='10px' viewBox='0 0 24 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><polygon points='12 14.75 0 2.75 2.15 0.6 12 10.5 21.85 0.65 24 2.8'></polygon></svg>");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 18px;
  }

  .scenario-form__group input[type=text]:focus,
  .scenario-form__group input[type=email]:focus,
  .scenario-form__group input[type=password]:focus,
  .scenario-form__group input[type=time]:focus,
  .scenario-form__group select:focus {
    border-color: $primary-70;
  }
  
  .scenario-form__group input[type=text].input-error,
  .scenario-form__group input[type=email].input-error,
  .scenario-form__group input[type=password].input-error,
  .scenario-form__group input[type=time].input-error,
  .scenario-form__group select.input-error {
    border-color: $alert-danger;
  }

  .input-group {
    position: relative;
  }
  
  .input-group .app-icon {
    position: absolute;
    bottom: 6px;
    left: 10px;
    z-index: 2;
  }

}


.icon {
  width: 28px;
  height: 28px;
  line-height: 38px;
  border-radius: 14px;
  background: $neutral-30;
  text-align: center;
  display: inline-block;
}

.icon--medium {
  width: 24px;
  height: 24px;
  line-height: 30px;
  border-radius: 12px;
}

.icon--success {
  background: $secondary-50;
  border: 1px solid $secondary-60;
}