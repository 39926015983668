/* @SWITCH */
.switch-wrapper {
  display: inline-block;
  position: relative;
}

.switch-handle {
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 4px;
  display: block;
  position: absolute;
  z-index: 2;
}

.switch--small .switch-handle {
  width: 26px;
  height: 26px;
}