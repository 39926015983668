/* MAGIC BOX */
.device-type__magicbox {

  /* DISPLAY */
  .magicbox__display {
    display: block;
    width: 40%;
    height: 318px;
    position: absolute;
    top: 0;
    left: 30%;
    border: 1px solid $primary-40;
    border-top: none;
  }

  /* BUTTONS */
  .magicbox__action-button1 {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    width: 30%;
    height: 159px;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid $primary-40;
    text-align: center;
  }

  .magicbox__action-button2 {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    width: 30%;
    height: 159px;
    position: absolute;
    top: 159px;
    left: 0;
    border-bottom: 1px solid $primary-40;
    text-align: center;
  }

  .magicbox__action-button3 {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    width: 30%;
    height: 159px;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom: 1px solid $primary-40;
    text-align: center;
  }

  .magicbox__action-button4 {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    width: 30%;
    height: 159px;
    position: absolute;
    top: 159px;
    right: 0;
    border-bottom: 1px solid $primary-40;
    text-align: center;
  }

  .action-button__wrapper {
    padding: 8px;
  }

  .action-button__icon {
    margin-bottom: 8px;
  }

  .action-button__tools {
    margin-bottom: 8px;
  }

  .action-button__description {
    margin-bottom: 8px;
  }

  .action-button__description {
    font-size: 12px;
  }

  .magicbox__display {
    padding: 16px;
  }

  .display__temperature {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .display__humidity {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .display__light {
    font-size: 32px;
    font-weight: 600;
  }

}


.mb-row__outputs {
  border-top: 1px solid $primary-40;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
}

.mb-row__output {
  padding: 8px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}