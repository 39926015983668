.room-new {
  position: relative;
  display: block;
  border: 2px solid $primary-40;
  background: $neutral-0;
  box-shadow: $box-shadow;
  border-radius: 4px;
  margin-bottom: 32px;
  overflow: hidden;
  width: 100%;
  min-width: 320px;
}

/* HEADER */
.room-new__header {
  padding: 8px;
  display: grid;
  grid-template-columns: auto 40px 40px;
  grid-gap: 8px;
  padding: 8px;
}

.room-new__title {
  font-weight: 600;
  font-size: 24px;
  color: #322859;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  padding: 8px;
}

.room-new__title-button {
  display: block;
  width: 40px;
  height: 40px;
  background: $button-primary-background;
  border: 1px solid $primary-90;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
}

.room-new__title-button:hover {
  background: $button-primary-background-hover;
}

/* PANEL SECTION */
.room-panel {
  border-top: 2px solid $primary-40;
  padding: 8px;
}


/* BUTTONS SECTION */
.room-new__buttons {
  border-top: 2px solid $primary-40;
  padding: 8px 8px 0 0;
}

.room-new__buttons:after {
  content: '';
  display: block;
  clear: both;
}

.room-button {
  border: 2px solid $primary-20;
  border-radius: 4px;
  background-color: $neutral-0;
  display: block;
  width: 74px;
  height: 74px;
  padding: 8px;
  float: left;
  margin: 0 0 8px 8px;
  transition: 200ms;
}

.room-button__icon {
  display: block;
  text-align: center;
}

.room-button__title {
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: $primary-20;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}


.room-button.room-button--off {
  border-color: $neutral-70;
  cursor: pointer;
}

.room-button.room-button--off:hover {
  border-color: $primary-70;
  box-shadow: $box-shadow;
}

.room-button.room-button--on {
  border-color: $neutral-100;
  cursor: pointer;
}

.room-button.room-button--on:hover {
  border-color: $primary-70;
  box-shadow: $box-shadow;
}


.room-button--off .room-button__title {
  color: $neutral-70;
}

.room-button--on .room-button__title {
  color: $neutral-100;
}
