/* @CLOCK ITEMS */
.clock-item {
  position: relative;
  display: block;
  border: 1px solid $primary-40;
  border-radius: 4px;
  background: $neutral-0;
  box-shadow: $box-shadow;
  margin-bottom: 16px;
}

.clock-item__header {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto 40px;
  border-bottom: 1px solid $primary-40;
}


.clock-item__header h3 {
  padding: 8px;
  color: $neutral-100;
}

.clock-item__body {
  padding: 16px;
}