/* @SCREEN SETUP */
.screen-setup {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.screen-buttons {
  display:grid;
  grid-template-columns: 100px 40px 100px auto 88px;
  grid-gap: 10px;
}

.screen-buttons--out {
  grid-template-columns: 100px 40px 100px auto auto 88px;
}

.screen-buttons__number {
  font-size: 28px;
  line-height: 48px;
  text-align: center;
  color: $neutral-100;
  font-size: 24px;
  font-weight: 600;
}

.screen-icon-selector {
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  height: 48px;
  line-height: 60px;
  background: $neutral-0;
  border: 1px solid $primary-40;
  padding: 0 8px;
}

.screen-icon-selector--disabled {
  background: $primary-20;
  color: $neutral-30;
  cursor: default;
}

.screen-icon-selector--active {
  border-color: $primary-70;
}

.screen-icon-list {
  position: absolute;
  z-index: 3;
  display: none;
  top: -1px;
  left: -1px;
  background: $neutral-0;
  min-width: 182px;
  min-height: 160px;
  border-radius: 4px;
  border: 1px solid $primary-70;
  box-shadow: $box-shadow;
  overflow: hidden;
}

.screen-icon-list__title {
  border-bottom: 1px solid $primary-40;
  text-transform: uppercase;
  height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0;
  margin: 0;
  line-height: 30px;
  background: $neutral-0;
}

.screen-icon-list__body {
  padding: 8px 4px 8px 8px
}

.screen-icon-list.screen-icon-list--active {
  display: block;
}

.screen-icon-list__icon {
  text-align: center;
  width: 48px;
  height: 48px;
  display: block;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: $box-shadow;
  border: 1px solid $primary-40;
  float: left;
  background: $neutral-0;
}

.screen-icon-list__icon.screen-icon-list__icon--active,
.screen-icon-list__icon:hover {
  border-color: $primary-70;
}