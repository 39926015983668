/* @CONTENT CARD */
.content-card {
  background: $neutral-0;
  border: 1px solid $primary-40;
  border-radius: 4px;
  margin-bottom: 24px;
}

.content-card__title {
  padding: 8px;
  border-bottom: 1px solid $primary-40;

  h3 {
    color: $primary-90;
  }
}

.content-card__body {
  padding: 8px;
}