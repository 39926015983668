@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}


@keyframes pulseshadow {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(176, 176, 219, 1);
  }
  50% {
    box-shadow: 0px 8px 16px 0px rgba(176, 176, 219, 1);
  }

  100% {
    box-shadow: 0px 0px 0px 0px rgba(176, 176, 219, 1);
  }
}


@keyframes pulseonout {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(0, 226, 255, 1);
  }
  50% {
    box-shadow: 0px 0px 8px 0px rgba(0, 226, 255, 1);
  }

  100% {
    box-shadow: 0px 0px 0px 0px rgba(0, 226, 255, 1);
  }
}