/* @TABNAV */
.tabnav {
  background: #DCE1F1;
  border-radius: 4px;
  padding: 8px;
  box-shadow: $box-shadow-inset;
  margin-top: 24px;
  border: 1px solid $neutral-0;
}

.tabnav--margin-bottom {
  margin-bottom: 16px;
}

.tabnav .tabnav-tab {
  display: block;
  text-decoration: none;
  color: $neutral-70;
  font-weight: 700;
  padding: 8px;
  border-radius: 4px;
}

.tabnav-tab.tabnav-tab--active {
  background: white;
  color: $neutral-100;
  border: 2px solid $primary-70;
  box-shadow: $box-shadow-tiny;
}

.tabnav-tab.tabnav-tab--active .tabnav-tab--highlight {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: $primary-70;
  margin-right: 8px;
  position: relative;
  top: -3px;
}

.tabnav-tab__devices-count {
  margin-left: 8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: $secondary-50;
  color: $neutral-0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid $secondary-60;
  font-weight: 600;
  text-shadow: 0px 0px 2px rgba(66, 68, 90, 1);
}

.tabnav-tab__devices-count.devices-count--active {
  animation: pulseshadow 2.5s ease infinite;
}

@media screen and (min-width: 1024px) {
  .tabnav .tabnav-tab {
    display: inline-block;
    margin-right: 8px;
  }
}