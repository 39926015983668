/* MAGIC LIGHT */
.device-type__magiclight {


  .magicswitch-light__row {
    display: grid;
    grid-template-columns: 60px auto 40px;
    grid-gap: 16px;
    border-bottom: 2px solid $primary-40;
    padding: 16px;
    height: 160px;
    align-items: center;
    transition: 0.3s;
  }

  .magicswitch-light__row:last-child {
    border: none;
  }


  .light-row__icon {
  }

  .light-row__description {
  }

  .light-row__state {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 32px;
    margin-top: 8px;
  }

  .light-row__switch {
  }


  .light-row__inputs {
    border-top: 1px solid $primary-40;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  .light-row__input {
    padding: 8px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
  }
}


.room-device-card:hover .device-type__magiclight .magicswitch-light__row {
  border-color: $primary-70;
}