/* @AUTH */
.auth-back-button {
  position: absolute;
  top: 16px;
  left: 16px;
}


.template-auth {
  background: $primary-5;
}


.template-auth .page-content {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
}


.auth-container {
  width: 90vw;
}


.auth-box__title {
  display: inline-block;
  font-size: 32px;
  font-weight: 700;
  color: $neutral-30;
}


.title--navigation {
  margin-bottom: 40px;
}


.auth-box__text {
  color: $neutral-70;
  margin-bottom: 32px;

  h3 {
    margin-bottom: 16px;
    font-size: 24px;
  }

  li {
    margin-bottom: 4px;
  }
}


.auth-box__register {
  border-top: 1px solid rgba(255, 255, 255, .1);
  padding: 16px 0;
  font-size: 16px;
  font-weight: 300;
  color: $neutral-70;
}


.auth-box__support-links {
  padding: 40px 0;
  text-align: center;
}


.auth-box__forgot-password {
  border-top: 1px solid rgba(255, 255, 255, .1);
  padding: 16px 0;
  font-size: 16px;
  font-weight: 300;
  color: $neutral-70;
}


.auth-box__register a,
.auth-box__forgot-password a {
  text-decoration: none;
}


.auth-version {
  text-align: center;
  font-size: 12px;
  padding: 8px 0;
  color: $neutral-70;
  font-weight: 300;
}


.auth-form__header {
  display: block;
  margin-bottom: 32px;
  text-align: center;
}


.auth-logo {
  display: block;
  width: 300px;
  height: 100px;
  background-image: url(../img/magicbox-logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px 80px;
  margin: 0 auto 24px;
}