/* @STOCK ITEM */
.stock-item {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto 40px;
  position: relative;
}

.stock-item__stock {
  border: 1px solid $primary-40;
  border-radius: 4px;
  box-shadow: $box-shadow;
  margin-bottom: 8px;
  height: 40px;
  padding: 8px;
  font-weight: 600;
  background: $neutral-0;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto 72px 72px 16px;
}

.stock-item__title {
  color: $neutral-100;
}

.stock-item__value,
.stock-item__percent,
.stock-item__rise {
  text-align: right;
}

.stock-item__percent {
  font-weight: 300;
  font-size: 14px;
}

.stock-item__rise {
  position: relative;
  margin-top: 2px;
}

.stock-item__percent--rise-up {
  color: #45B869;
}

.stock-item__percent--rise-down {
  color: #E72F54;
}