/* @SWITCH VERTICAL */
.switch--vertical {
  width: 42px;
  height: 70px;
  display: block;
  background: $neutral-30;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  box-shadow: $box-shadow-inset;
  position: relative;
  border: 1px solid $primary-40;

  .switch-state__on .switch-handle {
    top: 2px;
  }
  
  .switch-state__off .switch-handle {
    bottom: 2px;
  }
}

.switch--vertical[data-ison="false"] {
  background-color: $box-shadow-inset;
}

.switch--vertical[data-ison="true"] {
  background: $secondary-50;
  color: $neutral-0;
  border-color: #00B4CD;
  animation: pulseonout 2.5s ease infinite;
}



.switch--vertical .switch-state {
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  bottom: 8px;
  color: $neutral-0;

}

.switch--vertical .switch-state__on .switch-state {
  bottom: 4px;
  left: 8px;
}

.switch--vertical .switch-state__off .switch-state {
  top: 5px;
  left: 6.5px;
}