.button-link {
  display: block;
  width: 40px;
  height: 40px;
  background: $button-primary-background;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
}

.button-link:hover {
  background: $button-primary-background-hover;
}


.button-link.inactive {
  display: block;
  width: 40px;
  height: 40px;
  background: $primary-20;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
}