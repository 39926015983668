/* @SERVER PAGES 404 & 503 */
.server-page{
  width: 100%;
  height: 100vh;
  color: $neutral-0;
}

.server-page.server-page--error404{
  /* background-image: url(../img/bg-404.jpg); */
  background-size: cover;
  background-position: center;
}

.server-page.server-page--error503{
  /* background-image: url(../img/bg-503.jpg); */
  background-size: cover;
  background-position: center;
}

.server-page__content{
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
}

.server-page__wrapper{
  padding: 16px;
}

.server-page__card{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.server-page__title{
  font-size: 64px;
  margin-bottom: 16px;
  font-weight: 700;
}

.server-page__subtitle{
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 32px;
}