/* NEUTRAL */
$neutral-0: #FFFFFF;
$neutral-5: #F9F8FE;
$neutral-30: #bbbfcc;
$neutral-70: #828BA9;
$neutral-100: #000000;


/* PRIMARY */
$primary-90: #322859;
$primary-75: #3e3482;
$primary-70: #4D409E;
$primary-40: #A19BCD;
$primary-20: #D9D6E9;
$primary-10: #F1EEFF;
$primary-5: #F9F8FE;

/* SECONDARY */
$secondary-50: #00E2FF;
$secondary-60: #00B4CD;


/* @BUTTONS */
$button-primary-background: #493c93;
$button-primary-background-hover: #52459F;


/* SHADOWS */
$box-shadow: 0px 8px 16px 0px rgba(176, 176, 219, 1);
$box-shadow-small: 0px 4px 8px 0px rgba(176, 176, 219, 1);
$box-shadow-tiny: 0px 2px 4px 0px rgba(176, 176, 219, 1);
$box-shadow-selected: 0px 8px 32px 0px rgba(176, 176, 219, 1);
$box-shadow-inset: inset 0px 0px 4px rgba(176, 176, 219, 1);
$form-shadow: 0px 2px 4px 0px rgba(176, 176, 219, 1);
$navigation-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);


/* @MB-SCREEN */
$screen-background: rgba(0, 0, 0, 0.9);
$screen-overlay: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.25) 100%);
$screen-shadow-inset: inset 0 0 16px rgba(0, 0, 0, 1);
$screen-secondary-color: #a9a9d3;


/* @ALERTS */
$alert-info: #1f97c5;
$alert-success: #429321;
$alert-warning: #f76b1c;
$alert-danger: #f43f75;

.alert--info {
  background: #1f97c5;
  background: linear-gradient(to left, #32B3E5 0%,#1f97c5 100%);
}

.alert--success {
  background: #429321;
  background: linear-gradient(to left, #66D23A 0%,#429321 100%);
}

.alert--warning {
  background: #f76b1c;
  background: linear-gradient(to left, #F7941C 0%,#f76b1c 100%);
}

.alert--danger {
  background: #f43f75;
  background: linear-gradient(to left, #f9584b 0%,#f43f75 100%);
}


/* @ALERT BOXES */
$box-border-alert: #dd9a9a;
$box-background-alert: #ffe7e7;








//$button-primary-background: linear-gradient(to left, #593995 0%,#85469D 100%);
//$button-primary-background-hover: linear-gradient(to left, #4c2d85 0%,#6a367f 100%);
