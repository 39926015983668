.simple-tile {
  color: black !important;
  border: 1px solid $primary-40;
  background: $neutral-0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}


.simple-tile__item {
  padding: 16px;
  flex: 1;
  gap: 8px;
}


.simple-tile__item--column {
  display: flex;
  flex-direction: column;
}


.simple-tile__item--center {
  text-align: center;
}