/* @FORMS */
.form {

  .form-group {
    margin-bottom: 16px;
    position: relative;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
  
  .form-group label {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .form-group .label--small {
    font-size: 12px;
  }
  
  .form-group input[type=text],
  .form-group input[type=email],
  .form-group input[type=password],
  .form-group input[type=time],
  .form-group select {
    border: 1px solid $primary-40;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;
    background: $neutral-0;
    box-shadow: $form-shadow;
    backdrop-filter: blur(4px);
    width: 100%;
    color: $neutral-100;
    padding: 0 24px 0 48px;
    line-height: 48px;
    height: 48px;
    font-size: 16px;
    transition: all 0.5s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .form-group input:disabled,
  .form-group input:disabled,
  .form-group input:disabled,
  .form-group input:disabled,
  .form-group select:disabled {
    background: $primary-20;
    color: $primary-40;
  }
  
  .form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='16px' height='10px' viewBox='0 0 24 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><polygon points='12 14.75 0 2.75 2.15 0.6 12 10.5 21.85 0.65 24 2.8'></polygon></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 18px;
  }

  .form-group__switch {
    margin-bottom: 16px;
  }
  
  .form-group input[type=text]:focus,
  .form-group input[type=email]:focus,
  .form-group input[type=password]:focus,
  .form-group input[type=time]:focus,
  .form-group select:focus {
    border-color: $primary-70;
  }
  
  .form-group input[type=text].input-error,
  .form-group input[type=email].input-error,
  .form-group input[type=password].input-error,
  .form-group input[type=time].input-error,
  .form-group select.input-error {
    border-color: $alert-danger;
  }

  .input-group {
    position: relative;
  }
  
  .input-group .app-icon {
    position: absolute;
    bottom: 6px;
    left: 10px;
    z-index: 2;
  }
  
  .form-group .password-reveal {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 45px;
    right: 40px;
    display: block;
  }
  
  .form-explanation {
    font-size: 12px;
    color: $neutral-30;
  }

  .form-group--no-margin {
    margin-bottom: 0;
  }
  

  /* SLIDER */
  input[type=range] {
    height: 38px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background: none;
  }


  input[type=range]:focus {
    outline: none;
  }


  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: $form-shadow;
    background: #D9D9D9;
    border-radius: 5px;
  }


  input[type=range]::-webkit-slider-thumb {
    box-shadow: $form-shadow;
    background: $button-primary-background;
    border: 1px solid $primary-70;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15px;
  }

  .day-selection {
    margin-bottom: 16px;
  }

  .day-selection__day {
    display: inline-block;
    width: 42px;
    height: 42px;
    border: 2px solid $primary-40;
    box-shadow: $form-shadow;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    line-height: 40px;
    color: $neutral-70;
    font-weight: 600;
    background: $neutral-0;
  }

  .day-selection__day:hover {
    cursor: pointer;
    border: 2px solid $primary-70;
  }
  
  .day-selection__day--selected {
    border: 2px solid $primary-70;
    background: $neutral-0;
    color: $neutral-100;
  }

}

.form--margin-bottom {
  margin-bottom: 16px;
}

.form__footer {
  margin-top: 16px;
}


.form .form-group.form-group-capabilities {
  display: grid;
  grid-template-columns: auto; /* auto 40px 40px; */
  grid-gap: 20px;
}


.form .form-group .only-mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .form .form-group .only-mobile {
    display: none;
    border: 1px solid red;
  }
}


.form-section {
  margin-bottom: 32px;
}