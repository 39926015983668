/* @GENERAL RULES */
html, body {
  height: 100%;
}

body{
  background: $primary-5;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-attachment: fixed;
  color: $neutral-70;
}

body * {
  box-sizing: border-box;
  /* -webkit-appearance: none; */
  border-radius: 0;
}

body *:focus {
  outline: 0;
}

a{
  color: $primary-70;
  transition: all 0.25s ease;
}

a:hover, a:focus{
  color: $primary-70;
}

em{
  font-weight: 500;
}

sup { 
vertical-align: super;
font-size: smaller;
}

small {
  font-size: 12px;
  color: $neutral-70;
}

strong {
  font-weight: 700;
}

img {
  display: block;
}

h1{
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: $neutral-70;
}

h2{
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: $neutral-70;
}

h3{
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $neutral-70;
}

h4{
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: $neutral-70;
}

p {
  margin-bottom: 16px;
}

.text-center {
  text-align: center;
}

.csection.csection__small {
  margin-bottom: 8px;
}

.csection.csection__medium {
  margin-bottom: 24px;
}

.csection.csection__large {
  margin-bottom: 32px;
}

.csection-title {
  margin-bottom: 8px
}

.apexcharts-canvas {
  z-index: 1;
}

.content-main h4 {
  margin-bottom: 8px;
}

.margin-bottom--s {
  margin-bottom: 8px;
}

.margin-bottom--m {
  margin-bottom: 16px;
}

.margin-bottom--l {
  margin-bottom: 24px;
}

.margin-bottom--xl {
  margin-bottom: 32px;
}

.margin-bottom--xxl {
  margin-bottom: 40px;
}

.margin-bottom--xxxl {
  margin-bottom: 48px;
}