/* @RESPONSIVE BREAKPOINTS SHOW */

/* SHOW ONLY */
.show--only-s {
  display: block;
}

.show--only-m {
  display: none;
}

.show--only-l {
  display: none;
}

.show--only-xl {
  display: none;
}


@media screen and (min-width: 768px) {
  .show--only-s {
    display: none;
  }
  
  .show--only-m {
    display: block;
  }
  
  .show--only-l {
    display: none;
  }
  
  .show--only-xl {
    display: none;
  }
}


@media screen and (min-width: 1024px) {
  .show--only-s {
    display: none;
  }
  
  .show--only-m {
    display: none;
  }
  
  .show--only-l {
    display: block;
  }
  
  .show--only-xl {
    display: none;
  }
}


@media screen and (min-width: 1920px) {
  .show--only-s {
    display: none;
  }
  
  .show--only-m {
    display: none;
  }
  
  .show--only-l {
    display: none;
  }
  
  .show--only-xl {
    display: block;
  }
}



/* SHOW */
.show--from-m {
  display: none
}

.show--from-l {
  display: none
}

.show--from-xl {
  display: none
}


@media screen and (min-width: 768px) {
  .show--from-m {
    display: block
  }
}


@media screen and (min-width: 1024px) {
  .show--from-l {
    display: block
  }
}


@media screen and (min-width: 1920px) {
  .show--from-xl {
    display: block
  }
}