/* MAGIC MAIL */
.device-type__magicmail {
  
  .mail-row__icon {
    position: relative;
    height: 200px;
    padding: 16px;
    text-align: center;
  }

  .mail-row__tools {
    position: relative;
    height: 116px;
    padding: 16px;

    display: grid;
    grid-template-columns: 1fr 40px;
    grid-gap: 16px;
  }

  .mail-description__title {
    margin-bottom: 8px;
  }

  .mail-description__state {
    font-size: 32px;
    font-weight: 600;
  }

}