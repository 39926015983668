/* @ADD BOX */
.add-box {
  display: block;
  height: 120px;
  border: 3px dashed $primary-40;
  border-radius: 4px;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  color: $neutral-30;
  display: grid;
  grid-template-columns: 1fr;
}

.add-box:hover {
  border: 3px dashed $primary-70;
}

.add-box--disabled .add-box__circle {
  border-color: $neutral-30;
}

.add-box--disabled .add-box__link {
  color: $neutral-30;
}

.add-box--rooms {
  height: 318px;
}

.add-box--device {
  height: 382px;
}

.add-box--user {
  height: 258px;
}

.add-box__items {
  align-self: center;
  justify-self: center;
}

.add-box--magic-switch {
  height: 288px;
}

.add-box--magic-box {
  height: 288px;
}

.add-box__circle {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 3px solid $primary-70;
  line-height: 54px;
  margin: 0 auto;
}

.add-box__link {
  text-align: center;
  position: relative;
  bottom: -12px;
  color: $primary-70;
}