/* @MB SCREEN */
.mb-screen-wrapper {
  margin-bottom: 24px;
}


.mb-screen-wrapper--boxed {
  background: white;
  border: 1px solid $primary-40;
  border-radius: 4px;
}

.mb-screen {
  height: 320px;
  width: 240px;
  background: $screen-background;
  color: white;
  position: relative;
  font-family: 'Open Sans';
  margin: 0 auto;
}

.mb-screen-overlay {
  background: $screen-overlay;
  width: 100%;
  height: 100%;
  padding: 12px;
}

.mb-screen-offline-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-items: center;
  align-items: center;
}

.mb-screen-offline {
  color: #E82F55;
  text-transform: uppercase;
}

.mb-screen__temperature {
  color: white;
  position: relative;
  top: 56px;
  left: 18px;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: -3px;
}

.mb-screen__humidity {
  color: white;
  position: absolute;
  top: 124px;
  left: 32px;
  font-size: 40px;
  font-weight: 700;
}

.mb-screen__button {
  opacity: 0.4;
  cursor: pointer;
}

.mb-screen__button-wrapper { 
  border: 2px dashed transparent;
  border-radius: 4px;
  padding: 2px;
  width: 42px;
  height: 48px;
}

.mb-screen__button-wrapper--active { 
  border-color: #FF00B0;
}

.mb-screen__button--one {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;
}

.mb-screen__button--one.mb-screen__button-wrapper {
  top: 4px;
  left: 4px;
}

.mb-screen__button--two {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}

.mb-screen__button--two.mb-screen__button-wrapper {
  top: 4px;
  right: 4px;
}

.mb-screen__button--three {
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 1;
}

.mb-screen__button--three.mb-screen__button-wrapper {
  bottom: 4px;
  left: 4px;
}

.mb-screen__button--four {
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 1;
}

.mb-screen__button--four.mb-screen__button-wrapper {
  bottom: 4px;
  right: 4px;
}

.mb-screen__button--active {
  opacity: 1;
}

.mb-screen__status-bar {
  position: absolute;
  left: 12px;
  top: 182px;
  width: 216px;
}

.mb-screen__status-bar {
  display: grid;
  grid-template-columns: 10px 1fr 10px;
  justify-items: center;
  align-items: center;
}

.mb-screen__nav-items {
  display: grid;
  grid-template-columns: 40px 40px 40px 40px;
  justify-items: center;
  align-items: center;
  grid-gap: 6px;
}

.mb-screen__status-bar-item {
  width: 100%;
  height: 64px;
  width: 32px;
  padding-top: 16px;
  border-bottom: 3px solid white;
  font-size: 12px;
  text-align: center;
  opacity: 0.4;
}

.mb-screen__status-bar-item-wrapper {
  border: 2px dashed transparent;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
}

.mb-screen__status-bar-item-wrapper--selected {
  border-color: #FF00B0;
}

.status-bar-item__label {
  color: white;
  position: relative;
  top: -6px;
}

.status-bar-item--active {
  border-color: #FF00B0;
  opacity: 1;
}

.mb-screen__weather {
  position: absolute;
  top: 120px;
  right: 30px;
}

.mb-screen-item--inactive {
  opacity: 0.2;
}

.mb-screen-item--active {
  opacity: 1;
}

.mb-screen-item--selected {
  border: 2px dashed #FF00B0;
  border-radius: 4px;
  padding: 4px;
}