.order-screens__screen {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 30px auto 100px;
  position: relative;
  border: 1px solid $primary-40;
  border-radius: 4px;
  box-shadow: $box-shadow;
  margin-bottom: 8px;
  height: 58px;
  padding: 8px;
  color: $neutral-70;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  transition: all 0.25s ease;
}

.order-screens__screen {
  background: $neutral-0;
}

.order-screens__screen:hover {
  border-color: $primary-70;
}

.order-screens__screen--non-movable:hover {
  border: 1px solid $primary-40;
}

.order-screens__screen.order-screens__screen--dragged {
  border-color: $primary-70;
  box-shadow: $box-shadow;
}

.order-screens__screen--disabled {
  background: $neutral-0;
  color: $neutral-100;
}

.screen__enable {
  position: absolute;
  right: 8px;
  top: 8px;
}