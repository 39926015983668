/* @TAG */
.tag {
  display: inline-block;
  height: 40px;
  line-height: 34px;
  background: $neutral-0;
  border: 1px solid $primary-40;
  padding: 2px 40px 2px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  font-weight: normal;
  transition: all 0.25s ease;
  position: relative;
  color: $neutral-100;
  font-weight: 600;
}


.tag:hover {
  border-color: $primary-70;
}

.tag__remove {
  position: absolute;
  right: 4px;
  top: 4px;
  background: $button-primary-background;
  border: 1px solid $primary-70;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  line-height: 42px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.form .form-group .tag__remove .app-icon {
  position: absolute;
  left: 2px;
  top: 0;
}