.label-location {
  font-size: 14px;
  color: $primary-70;
  display: inline-block;
  padding: 4px 16px 4px 40px;
  border-radius: 4px;
  background-color: $primary-20;
  font-weight: 600;
  position: relative;
}

.label-location .app-icon--location-rooms {
  position: absolute;
  top: 4px;
  left: 8px;
}

.label-info {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  display: inline-block;
  padding: 0 8px;
  text-align:center;
  border-radius: 2px;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: $neutral-0;
  background: $secondary-50;
  border: 1px solid $secondary-60;
  text-shadow: 0px 0px 2px rgba(66, 68, 90, 1);
}


.label-device-serial {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  display: inline-block;
  padding: 0 8px;
  text-align:center;
  border-radius: 2px;
  text-transform: uppercase;
  margin-bottom: 32px;
  color: $neutral-70;
  background: $neutral-0;
  border: 1px solid $neutral-70;
}

.device-card--active .label-device-serial {
  color: $neutral-100;
  border-color: $neutral-100;
}


.label-device {
  font-size: 14px;
  color: $primary-40;
  display: inline-block;
  padding: 4px 16px 4px 28px;
  border-radius: 4px;
  background-color: $primary-20;
  font-weight: 600;
  position: relative;
}

.label-device .app-icon--device {
  position: absolute;
  top: 7px;
  left: 8px;
}