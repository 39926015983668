/* @PASSWORD STRENGTH METER */
.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 240px;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: $neutral-0;
  border: 1px solid $primary-40;
  border-radius: 4px;
}

.auth-form .form-group .password-strength-meter-label {
  display: block;
  font-size: 12px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 4px;
  background-size: 32px 24px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: $alert-danger;
}
.strength-Fair::-webkit-progress-value {
  background-color: $alert-warning;
}
.strength-Good::-webkit-progress-value {
  background-color: $alert-info;
}
.strength-Strong::-webkit-progress-value {
  background-color: $alert-success;
}