/* @PAGE */
.page-content {
  padding: 16px;
}

.page-content__header {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.page-content__header .btn-square--back {
  margin-right: 16px;
}

.page-content__header h1 {
  font-size: 22px;
  line-height: 40px;
}

.page-content__button-links a {
  position: absolute;
  top: 12px;
  right: 12px;
}

.content-main {
  margin-top: 24px;
  margin-bottom: 88px;
}

.content-main > h3 {
  margin-bottom: 32px;
}

.content-row {
  margin-bottom: 24px;
  padding: 8px;
  background: $neutral-0;
  border-radius: 4px;
  box-shadow: $box-shadow;
}



@media screen and (min-width: 768px) {

  .page {
    height: auto;
  }

  .page .page-content__grid {
    display: grid;
    grid-template-columns: 120px auto;
  }

  .page-content {
    padding: 32px;
  }
  
  .page-content__header h1 {
    font-size: 32px;
    line-height: 44px;
  }

}


@media screen and (min-width: 1024px) {
  .page .page-content__grid {
    display: grid;
    grid-template-columns: 160px auto;
  }
}



@media screen and (min-width: 1920px) {

  .page-content__wrapper {
    padding: 40px;
  }

  .page {
    height: auto;
  }

  .page .page-content__grid {
    display: grid;
    grid-template-columns: 320px auto;
  }

}