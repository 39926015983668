.toolbar {
  line-height: 24px;
  background: white;
  border-bottom: 1px solid $primary-40;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.toolbar__logo {
  display: block;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: url(../img/magicbox-logo-navigation-vertical.svg);
  background-size: 50px 50px;
}  

.toolbar__item-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.toolbar-weather__main {
  display: grid;
  grid-template-columns: auto;
  position: relative;
  width: 34px;
}

.toolbar-weather__weather-status {
  margin-top: -2px;
}

.toolbar-weather__temperature {
  font-size: 14px;
  line-height: 14px;
  color: $neutral-100;
  font-weight: 700;
  margin-top: -4px;
}

.toolbar__item-box {
  display: grid;
  grid-template-columns: auto;
  border: 1px solid $primary-20;
  background: $primary-5;
  border-radius: 8px;
  padding: 6px;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  min-width: 110px;
  text-decoration: none;
}

.toolbar__item-box span {
  color: $neutral-100;
}

.toolbar-weather__wind-alert {
  position: absolute;
  top: 12px;
  left: -24px;
}

.toolbar-weather__rain-alert {
  position: absolute;
  top: 12px;
  left: -24px;
}

@media screen and (min-width: 768px) {
  .toolbar {
    grid-template-columns: 1fr auto;
    padding: 8px 32px;
  }

  .toolbar__logo {
    display: none;
  }  
}