/* @AUTH FORM */
.auth-container {
  max-width: 450px;
}


.auth-form {

  .form-group {
    margin-bottom: 24px;
    position: relative;
  }
  
  .form-group label {
    display: none;
  }
  
  .form-group input[type=text],
  .form-group input[type=email],
  .form-group input[type=password],
  .form-group select {
    border: 1px solid $primary-40;
    position: relative;
    z-index: 1;
    background: inherit;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: $form-shadow;
    background-color: $neutral-0;
    backdrop-filter: blur(4px);
    width: 100%;
    color: $neutral-100;
    padding: 0 24px 0 40px;
    line-height: 48px;
    font-size: 16px;
    transition: all 0.5s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  .form-group input[type=text]:focus,
  .form-group input[type=email]:focus,
  .form-group input[type=password]:focus,
  .form-group select:focus {
    border-color: $primary-70;
  }
  
  .form-group input[type=text].input-error,
  .form-group input[type=email].input-error,
  .form-group input[type=password].input-error,
  .form-group select.input-error {
    border-color: $alert-danger;
  }
  
  input[type=submit]{
    cursor: pointer;
  }
  
  .input-group {
    position: relative;
  }
  
  .form-group .app-icon--email {
    position: absolute;
    left: 8px;
    z-index: 2;
    top: 12px;
  }
  
  .form-group .app-icon--lock {
    position: absolute;
    left: 10px;
    z-index: 2;
    top: 12px;
  }
  
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $neutral-70;
  }

  .form-group .password-reveal {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    right: 10px;
    top: 15px;
  }

}

.auth-box .btn-square {
  margin-right: 8px;
}


.form-group .pin-number {
  width: 60px;
  height: 80px;
  font-size: 60px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid $primary-40;
  box-shadow: $form-shadow;
  text-align: center;
  color: $neutral-100;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}