/* @USERS */
.users {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 600px) {
  .users {
    grid-gap: 32px;
  }
}

@media screen and (min-width: 1024px) {
  .users {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1920px) {
  .users {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.user-card {
  transition: 0.3s;
  position: relative;
  display: block;
  border: 2px solid $primary-40;
  border-radius: 4px;
  background: $neutral-0;
  box-shadow: $box-shadow;
  text-decoration: none;
}

.user-card:hover {
  border: 2px solid $primary-70;
  box-shadow: $box-shadow-selected;
}

.user-card__header {
  display: grid;
  grid-template-columns: auto 40px;
  padding: 8px;
}

.user-card__title {
  font-weight: 600;
  font-size: 24px;
  color: #322859;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  padding: 8px;
  text-decoration: none;
}

.user-card__header-button {
  display: block;
  width: 40px;
  height: 40px;
  background: $primary-20;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
}

.user-card__body {
  padding: 8px;
  text-align: center;
}

.user-card__icon {
  padding: 0 8px 8px;
}

.user-card__email {
  font-weight: 700;
  color: $neutral-100;
  padding: 8px;
}

.user-card__details {
  color: $neutral-100;
}

.user-card__registered {
  font-size: 12px;
  padding: 8px 8px 16px;;
}

.user-card__manage {
  border-top: 1px solid $primary-40;
  padding: 8px;
  text-align:center;
  text-transform: uppercase;
  font-weight: 600;
  color: $primary-70;
  display: block;
  text-decoration: none;
}