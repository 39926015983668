/* @SCENARIOS */
.scenarios {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

.scenario {
  transition: 0.3s;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 70px;
  grid-gap: 16px;
  align-items: center;
  border: 2px solid $primary-40;
  background: $neutral-0;
  padding: 8px;
  border-radius: 4px;
  text-decoration: none;
  box-shadow: $box-shadow-tiny;
}


.scenario:hover {
  border: 2px solid $primary-70;
}

.scenario a {
  text-decoration: none;
}

.scenario__title {
  font-weight: 600;
  color: $primary-90;
}

.scenario__header {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 24px;
  align-items: center;
}

.scenario__header .app-icon--scenarios {
  position: relative;
  top: 4px;
}

.scenario__switch {
  height: 30px;
}

.scenario.scenario--disabled {
  background: $primary-10;

  .scenario__title {
    color: $primary-40
  }

}
