/* @CONTENT CARD */
.content-card-alert {
  background: $box-background-alert;
  border: 1px solid $box-border-alert;
  border-radius: 4px;
  margin-bottom: 24px;
}

.content-card-alert__title {
  padding: 8px;
  border-bottom: 1px solid $box-border-alert;

  h3 {
    color: $primary-70;
  }
}

.content-card-alert__body {
  padding: 8px;
}