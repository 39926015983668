/* WSCONNECTOR */
.wsconnector {
  width: 100%;
  height: 4px;
  background: $neutral-0;
}

.wsconnector--connected {
  background: $secondary-50;
  border-bottom: 1px solid $secondary-60;
  animation: pulseonout 2.5s ease infinite;
}

.wsconnector--disconnected {
  background: $neutral-30;
}