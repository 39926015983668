/* @SWITCH HORIZONTAL ON OFF */
.switch--horizontal-on-off .switch {
  width: 100px;
  height: 42px;
  display: block;
  background: $neutral-30;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  box-shadow: $box-shadow-inset;
  position: relative;
}

.switch--horizontal-on-off.switch-state__off .switch {
  background: red;
}

.switch--horizontal-on-off.switch-state__on .switch {
  background-color: green;
}

.switch-state {
  position: absolute;
  font-size: 18px;
  bottom: 8px;
  color: $neutral-0;
}

.switch-state__on .switch-state {
  left: 8px;
}

.switch-state__off .switch-state {
  right: 8px;
}

.switch-state__on .switch-handle {
  right: 2px;
  border: 1px solid $secondary-60;
}

.switch-state__off .switch-handle {
  left: 2px;
  border: 1px solid $primary-40;
}


/* SMALL ON OFF */
.switch--horizontal-on-off .switch.switch--small {
  width: 70px;
  height: 32px;
}

.switch--small .switch-state {
  position: absolute;
  font-size: 16px;
  bottom: 3px;
}

.switch--small .switch-state__off {
  left: 34px;
}




/* @SWITCH HORIZONTAL TRUE/FALSE */
.switch--horizontal-true-false .switch {
  width: 100px;
  height: 40px;
  display: block;
  background: $primary-20;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  box-shadow: $box-shadow-inset;
  position: relative;
  border: 1px solid $primary-40;
}

.switch--horizontal-true-false.switch-state__off .switch {
  background: $box-shadow-inset;
}

.switch--horizontal-true-false.switch-state__on .switch {
  color: $neutral-0;
  background: $secondary-50;
  background: $box-shadow-inset;
  border-color: $secondary-60;
}

.switch-state {
  position: absolute;
  font-size: 18px;
  bottom: 8px;
  color: $neutral-0;
}

.switch-state__on .switch-state {
  left: 8px;
}

.switch-state__off .switch-state {
  right: 8px;
}

.switch-state__on .switch-handle {
  right: 2px;
}

.switch-state__off .switch-handle {
  left: 2px;
}

.app-icon--switch-cross {
  position: relative;
  top: 4px;
  right: 4px;
}

.app-icon--switch-tick {
  position: relative;
  top: 3px;
  left: 3px;
}


/* SMALL TRUE/FALSE */
.switch--horizontal-true-false .switch.switch--small {
  width: 70px;
  height: 32px;
}

.switch--small .switch-state {
  position: absolute;
  font-size: 16px;
  bottom: 3px;
}

.switch--small .switch-state__off {
  left: 34px;
}


.switch-title {
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 16px;
  top: -14px;
}