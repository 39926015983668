/* PANEL */
.ink-panel {
  border: 3px solid $primary-20;
  border-radius: 16px;
  display: block;
  width: 300px;
  height: 300px;
  padding: 1px;
  margin: 0 auto;
  font-family: 'Open Sans';
}

.ink-panel.ink-panel--active {
  border-color: $neutral-100;
}

.ink-panel-ui {
  border: 1px solid $primary-20;
  border-radius: 12px;
  display: block;
  width: 292px;
  height: 292px;
  position: relative;
}

.ink-panel--active .ink-panel-ui {
  border-color: $neutral-100;
}

/* PANEL BUTTONS */
.ink-panel__button-top-left {
  width: 50%;
  height: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ink-panel--active .ink-panel__button-top-left {
  cursor: pointer;
}

.ink-panel__button-top-right {
  width: 50%;
  height: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.ink-panel--active .ink-panel__button-top-right {
  cursor: pointer;
}

.ink-panel__button-bottom-left {
  width: 50%;
  height: 50%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ink-panel--active .ink-panel__button-bottom-left {
  cursor: pointer;
}

.ink-panel__button-bottom-right {
  width: 50%;
  height: 50%;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.ink-panel--active .ink-panel__button-bottom-right {
  cursor: pointer;
}

.ink-panel__display {
  border: 2px solid $primary-20;
  background: $neutral-0;
  width: 160px;
  height: 210px;
  display: block;
  position: absolute;
  z-index: 1;
  top: 42px;
  left: 65px;
}


/* PANEL DISPLAY */
.ink-panel--active .ink-panel__display {
  border-color: $neutral-100;
}

.display__wifi-icon {
  position: absolute;
  top: -2px;
  left: 72px;
}

.display__time {
  position: absolute;
  top: 10px;
  left: 68px;
  font-size: 8px;
  color: $primary-20;
}

.ink-panel--active .display__time {
  color: $neutral-100;
}

.display__tempertature {
  position: absolute;
  top: 48px;
  left: 16px;
  font-size: 42px;
  font-weight: 700;
  color: $primary-20;
}

.ink-panel--active .display__tempertature {
  color: $neutral-100;
}

.display__temperature-decimal {
  font-size: 24px;
  color: $primary-20;
}

.ink-panel--active .display__temperature-decimal {
  color: $neutral-100;
}

.display__humidity {
  position: absolute;
  top: 94px;
  left: 16px;
  font-size: 30px;
  font-weight: 700;
  color: $primary-20;
}

.ink-panel--active .display__humidity  {
  color: $neutral-100;
}

.display__weather {
  position: absolute;
  top: 94px;
  right: 16px;
}

.display__status {
  position: absolute;
  top: 136px;
  left: 16px;
  width: 124px;
  height: 32px;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.display__status-icon {
  border-bottom: 2px solid $primary-20;
  position: relative;
  text-align: center;
  display: block;
  width: 100%;
}

.ink-panel--active .display__status-icon {
  border-color: $neutral-100;
}

.display__status-icon.display__status-icon--active {
  border-color: #7C22A4;
}

.display__status-icon-title {
  font-size: 8px;
  line-height: 8px;
  color: $neutral-100;
  position: absolute;
  top: 20px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  width: 100%;
}

.display__status-icon-icon {
  position: absolute;
  top: 0;
  left: 4px;
}


/* PANEL DECORATIVE ELEMENTS */
.ink-panel__branding {
  font-size: 12px;
  position: absolute;
  z-index: 2;
  top: 230px;
  left: 112px;
  color: $primary-20;
  display: none;
}

.ink-panel__branding span {
  color: $primary-20;
  font-weight: 700;
}

.ink-panel--active .ink-panel__branding,
.ink-panel--active .ink-panel__branding span {
  color: $neutral-100;
}


.ink-panel__sensor-top {
  border: 3px solid $primary-20;
  display: block;
  width: 21px;
  height: 11px;
  border-radius: 11px;
  position: absolute;
  z-index: 2;
  top: 14px;
  left: 134px;
}

.ink-panel--active .ink-panel__sensor-top {
  border-color: $neutral-100;
}

.ink-panel__divider-top {
  background: $primary-20;
  display: block;
  width: 2px;
  height: 8px;
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 144px;
}

.ink-panel--active .ink-panel__divider-top {
  background: $neutral-100;
}


.ink-panel__divider-bottom {
  background: $primary-20;
  display: block;
  width: 2px;
  height: 18px;
  position: absolute;
  z-index: 2;
  top: 264px;
  left: 144px;
}

.ink-panel--active .ink-panel__divider-bottom {
  background: $neutral-100;
}


.ink-panel__divider-left {
  background: $primary-20;
  display: block;
  width: 40px;
  height: 2px;
  position: absolute;
  z-index: 2;
  top: 144px;
  left: 14px;
}

.ink-panel--active .ink-panel__divider-left {
  background: $neutral-100;
}


.ink-panel__divider-right {
  background: $primary-20;
  display: block;
  width: 40px;
  height: 2px;
  position: absolute;
  z-index: 2;
  top: 144px;
  right: 14px;
}

.ink-panel--active .ink-panel__divider-right {
  background: $neutral-100;
}


.ink-panel__sensor-bottom-left {
  border: 3px solid $primary-20;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  bottom: 12px;
  left: 104px;
}

.ink-panel--active .ink-panel__sensor-bottom-left {
  border-color: $neutral-100;
}


.ink-panel__sensor-bottom-right {
  border: 3px solid $primary-20;
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  bottom: 12px;
  right: 104px;
}

.ink-panel--active .ink-panel__sensor-bottom-right {
  border-color: $neutral-100;
}


.ink-panel__button-marker-top-left {
  position: absolute;
  top: 2px;
  left: 8px;
}

.ink-panel__button-marker-top-right {
  position: absolute;
  top: 2px;
  right: 8px;
}

.ink-panel__button-marker-bottom-left {
  position: absolute;
  bottom: 2px;
  left: 8px;
}

.ink-panel__button-marker-bottom-right {
  position: absolute;
  bottom: 2px;
  right: 8px;
}

.display__corner-icon-top-left {
  position: absolute;
  top: 2px;
  left: 2px;
}

.display__corner-icon-top-right {
  position: absolute;
  top: 2px;
  right: 2px;
}

.display__corner-icon-bottom-left {
  position: absolute;
  bottom: -2px;
  left: 2px;
}

.display__corner-icon-bottom-right {
  position: absolute;
  bottom: -2px;
  right: 2px;
}