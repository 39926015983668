/* TRAFFIC LIGHT */

.io-traffic-light__wrapper {
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 1px;
}

.io-traffic-light__light {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid $neutral-70;
  background: $primary-20;
  border-radius: 6px;
}

.io-traffic-light__light--on {
  background: $secondary-50;
  border-color: $secondary-60;
  animation: pulseonout 2.5s ease infinite;
}