.settings-row {
  
  select {
    border: 1px solid $primary-40;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;
    background: $neutral-0;
    box-shadow: $box-shadow;
    backdrop-filter: blur(4px);
    width: 100%;
    color: $neutral-100;
    padding: 0 24px 0 8px;
    line-height: 48px;
    height: 48px;
    font-size: 16px;
    transition: all 0.5s ease;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='16px' height='10px' viewBox='0 0 24 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><polygon points='12 14.75 0 2.75 2.15 0.6 12 10.5 21.85 0.65 24 2.8'></polygon></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 18px;
  }


  select:disabled {
    background: $primary-20;
    color: $primary-40;
  }

  select:focus {
    border-color: $primary-70;
  }

  select.input-error {
    border-color: $alert-danger;
  }
}