/* @STATUS ICONS */
.status-icons__form {
  margin-top: 16px;
}


.status-icons__screen {
  border-radius: 4px;
  background: $screen-background;
  box-shadow: $screen-shadow-inset;
  margin-bottom: 16px;
}

.status-icons__screen-overlay {
  background: $screen-overlay;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.status-icons__icons {
  display: block;
}

.status-icon__icons-clear {
  content: "";
  clear: both;
  display: table;
}

.status-icon__wrapper {
  padding: 8px;
  border: 2px dashed transparent;
  float: left;
  margin-right: 8px;
  border-radius: 4px;
}

.status-icon__wrapper:hover {
  border-color: $screen-secondary-color;
}

.status-icon__wrapper--active {
  border-color: $neutral-0;
}

.status-icon {
  display: grid;
  align-content: center;
  text-align: center;
  height: 88px;
  width: 64px;
  cursor: pointer;
}

.status-icon--icon {
  border-bottom: 3px solid white;
}

.status-icons__icon-add-circle {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid $screen-secondary-color;
  line-height: 54px;
  margin: 0 auto;
  text-align: center;
}

.status-icon__setup-form {
  position: relative;
  display: block;
  border: 1px solid $primary-40;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
  margin-bottom: 16px;
  padding: 16px;
}

.status-icon__form-row {
  display:grid;
  grid-template-columns: 40px 1fr 1fr 40px;
  grid-column-gap: 10px;
}


.status-icon-selector {
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  background: $neutral-0;
  border: 1px solid $primary-40;
}

.status-icon-selector--active {
  border-color: $primary-70;
}

.status-icon__button {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  cursor: pointer;
  box-shadow: $box-shadow;
  background: $button-primary-background;
  color: $neutral-0;
  border-color: $primary-70;
}

.status-icon__button:hover {
background: $button-primary-background;
color: $neutral-0;
border-color: $primary-70;
}