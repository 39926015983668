/* @MOBILE NAVIGATION */
.mobile-navigation {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  height: 64px;
  display: block;
  background: rgba(255,255,255,0.9);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
}

@media screen and (min-width: 768px) {
  .mobile-navigation {
    display: none;
  }
}

.mobile-navigation__item-link {
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
  color: $neutral-70;
  padding: 8px;
  position: relative;
}

.mobile-navigation__item-link.mobile-navigation__item--current .mobile-navigation__item-title {
  color: $neutral-100;
}

.app-icon--navigation {
  position: relative;
  height: 38px;
  display: block;
}

.mobile-navigation__item-title {
  position: relative;
  bottom: 8px;
}


.mobile-navigation-item__item-count {
  display: block;
  position: absolute;
  border: 1px solid black;
  background: black;
  color: white;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  top: 8px;
  left: 8px;
  text-shadow: 0px 0px 2px rgba(66, 68, 90, 1);
}


.mobile-navigation-item__item-count--alarm {
  background: $secondary-50;
  border-color: $secondary-60;
}