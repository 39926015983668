/* INFOBOX */

.infobox {
  position: relative;
  display: block;
  border: 1px solid $primary-40;
  background: $neutral-0;
  box-shadow: $box-shadow;
  padding: 16px;
  border-radius: 4px;
  text-decoration: none;
}