.labels-filter {
  margin-bottom: 16px;
}

.labels-filter__title {
  font-size: 12px;
  font-weight: bold;
}

.labels-filter__item {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 2px solid $primary-40;
  background: $neutral-0;
  cursor: pointer;
}

.labels-filter__item:hover {
  cursor: pointer;
  border: 2px solid $primary-70;
}

.labels-filter__item--event--selected {
  border: 2px solid $primary-70;
  background: $neutral-0;
  color: $neutral-100;
}

.labels-filter__item-icon {
  position: relative;
  top: 3px;
  margin-right: 8px;
}

.labels-filter__item-text {
  font-size: 14px;
  font-weight: 700;
  color: $neutral-70;
  text-transform: uppercase;
  position: relative;
  top: -3px;
}