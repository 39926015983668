/* @ROOM DEVICES */
.room-devices {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 1024px) {
  .room-devices {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
}

@media screen and (min-width: 1920px) {
  .room-devices {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.room-device-card {
  position: relative;
  display: block;
  border: 2px solid $primary-40;
  background: $neutral-0;
  box-shadow: $box-shadow;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
  height: 382px;
}

.room-device-card:hover {
  border: 2px solid $primary-70;
  box-shadow: $box-shadow-selected;
}

.room-device-card__header {
  color: $primary-90;
  padding: 8px;
  border-bottom: 2px solid $primary-40;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  transition: 0.3s;
}

.room-device-card:hover .room-device-card__header {
  border-bottom: 2px solid $primary-70;
}

.device-card__tools {
  display: grid;
  grid-template-columns: 40px;
  grid-gap: 8px;
}

.room-device-card__body {
  position: relative;
  height: 360px;
}

.room-device-card__footer {
  display: block;
  border-top: 1px solid $primary-40;
  padding: 8px;
  text-align:center;
  text-transform: uppercase;
  font-weight: 600;
  color: $primary-70;
  text-decoration: none;
}

.room-device-card.device-card--active {
  background: $neutral-0;

  .room-device-card__header {
    color: $primary-70;
  }

  .room-device-card__header h3 {
    color: $neutral-100;
  }

  .doors-description__title {
    color: $neutral-100;
  }

  .doors-description__state {
    color: $neutral-100;
  }

  .state--on {
    color: $secondary-50;
  }

  .state--off {
    color: $neutral-70;
  }


}