/* @ROOMS */
.rooms {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 600px) {
  .rooms {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
}

@media screen and (min-width: 1440px) {
  .rooms {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 1920px) {
  .rooms {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.room {
  position: relative;
  display: block;
  border: 2px solid $primary-40;
  background: $neutral-0;
  box-shadow: $box-shadow;
  border-radius: 4px;
  text-decoration: none;
  height: 318px;
  transition: 0.3s;
}

.room:hover {
  border: 2px solid $primary-70;
  box-shadow: $box-shadow-selected;
}

.room__header {
  display: grid;
  grid-template-columns: auto 40px;
  padding: 8px;
}

.room__title {
  font-weight: 600;
  font-size: 24px;
  color: #322859;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  padding: 8px;
}

.room__devices {
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.room__devices-device {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  text-align: center;
  line-height: 70px;
  background: $primary-20;
}

.room__devices-device--magic-panel {
  line-height: 80px;
}

.room__image {
  margin-top: 16px;
  height: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.room__labels {
  text-align: center;
  padding: 16px;
}

.room__manage {
  border-top: 1px solid $primary-40;
  padding: 8px;
  text-align:center;
  text-transform: uppercase;
  font-weight: 600;
  color: $primary-70;
}