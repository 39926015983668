/* @ALERT */
.alert-wrapper {
  margin-top: 4px;
}

.alert {
  display: block;
  background: $neutral-0;
  border: 1px solid $primary-40;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 24px auto;
  justify-items: center;
  align-items: center;
  font-size: 13px;
  line-height: 21px;

  .icon-alert-error {
    position: relative;
    top: 10px;
  }

  .icon-alert-info {
    position: relative;
    top: 10px;
  }

  .icon-alert-success {
    position: relative;
    top: 10px;
  }

  .icon-alert-warning {
    position: relative;
    top: 10px;
  }
}

.alert--info {
  border-color: #32B3E5;

  .alert-icon {
    background: #32B3E5;
  }

  .alert-content {
    border-left: 1px solid #32B3E5;
    color: #1c6785;
  }

  a {
    color: #1c6785;
  }
}

.alert--success {
  border-color: #66D23A;

  .alert-icon {
    background: #66D23A;
  }

  .alert-content {
    border-left: 1px solid #66D23A;
    color: #3b7c21;
  }

  a {
    color: #3b7c21;
  }
}

.alert--warning {
  border-color: #eccd51;

  .alert-icon {
    background: #eccd51;
  }

  .alert-content {
    border-left: 1px solid #eccd51;
    color: #7e6d29;
  }

  a {
    color: #7e6d29;
  }
}

.alert--error {
  border-color: #f43f75;

  .alert-icon {
    background: #f43f75;
  }

  .alert-content {
    border-left: 1px solid #be305b;
    color: #771a36;
  }

  a {
    color: #771a36;
  }
}

.alert-icon {
  width: 100%;
  height: 100%;
  text-align: center;
}

.alert-content {
  width: 100%;
  padding: 8px 16px 8px 16px;
  color: $neutral-0;
}