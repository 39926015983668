/* @PAGINATION */
.pagination {
  margin-top: 24px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 40px 40px auto;
}

.pagination__left-arrow {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: $button-primary-background;
  color: $neutral-0;
  border-color: $primary-70;
  text-align: center;
  line-height: 40px;
}

.pagination__right-arrow {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: $button-primary-background;
  color: $neutral-0;
  border-color: $primary-70;
  text-align: center;
}

.pagination__right-arrow .app-icon,
.pagination__left-arrow .app-icon {
  top: 4px;
  position: relative;
}

.pagination__info {
  text-align: right;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
}

.pagination__perpage {
  margin-right: 40px;
}

.pagination--clickable {
  cursor: pointer;
}

.pagination__left-arrow.pagination--disabled,
.pagination__right-arrow.pagination--disabled {
  background: $primary-20;
}

.pagination__perpage-select {
  border: none;
  background: none;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  width: 60px;
  margin-right: 8px;
  text-align: right;
}