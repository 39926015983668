.screen-setup__control-buttons {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  .screen-button {
    background: $neutral-0;
    border: 1px solid $primary-40;
    border-radius: 4px;
    padding: 8px;
    transition: all 0.5s ease;
  }

  .screen-button:hover {
    border-color: $primary-70;
    box-shadow: $box-shadow;
  }

  .screen-button-title {
    margin-bottom: 8px;

    h3 {
      color: $neutral-100;
      text-transform: uppercase;
    }
  }

  .screen-button-title.screen-button-title--disabled {
    h3 {
      color: $neutral-70;
    }
  }

  .screen-icon-enabled {
    margin-bottom: 4px;
  }

  .corner-button-input {
    margin-bottom: 8px;
    box-shadow: $box-shadow;
  }
}


@media screen and (min-width: 768px) {
  .screen-setup__control-buttons {
    grid-template-columns: 1fr 1fr;
  }
}


@media screen and (min-width: 1440px) {
  .screen-setup__control-buttons {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}