/* @BUTTONS */
.btn {
  -webkit-appearance: none;
  font-size: 18px;
  padding: 8px 24px;
  border-radius: 4px;
  line-height: 30px;
  text-align: center;
  text-decoration:none;
  font-weight: 700;
  display: inline-block;
  transition: all 0.25s ease;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  background: $neutral-100;
  border: 1px solid $neutral-100;
  color: $neutral-0;
}

@media screen and (min-width: 768px) {
  .btn {
    width: auto;
  }
}

.btn:hover {
  background: $neutral-100;
  color: $neutral-0;
  border-color: $neutral-100;
}

.btn--primary {
  background: $button-primary-background;
  color: $neutral-0;
  border-color: $primary-90;
}

.btn--primary:hover {
  background: $button-primary-background-hover;
  color: $neutral-0;
  border-color: $primary-90;
}

.btn--small {
  font-size: 14px;
  padding: 4px 16px;
  line-height: 24px;
}

.btn--google {
  background: $neutral-0;  
  color: $neutral-100;
  border-color: $neutral-30;
}

.btn--google:hover {
  background: $neutral-0;  
  color: $neutral-100;
  border-color: $neutral-30;
}

.btn--google .app-icon--google {
  position: absolute;
  top: 12px;
  margin-left: -32px;
}

.btn--centered {
  margin: 0 auto;
  display: block;
  text-align: center;
  width: max-content;
}

.btn-pure {
  -webkit-appearance: none;
  font-size: 18px;
  border-radius: 20px;
  line-height: 50px;
  width: 40px;
  height: 40px;
  text-align: center;
  text-decoration:none;
  text-transform: uppercase;
  display: inline-block;
  font-weight: normal;
  transition: all 0.25s ease;
  letter-spacing: 1px;
  box-sizing: border-box;
  cursor: pointer;
}

.btn-square {
  -webkit-appearance: none;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  line-height:48px;
  text-align: center;
  text-decoration:none;
  text-transform: uppercase;
  display: inline-block;
  font-weight: normal;
  transition: all 0.25s ease;
  letter-spacing: 1px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: none;
  background: $button-primary-background;
  border: 1px solid $primary-90;
  color: $neutral-0;
}

.btn-square:hover {
  background: $button-primary-background-hover;
  color: $neutral-0;
  border-color: $primary-90;
}


.btn--pulsate {
  // animation: pulseshadow 2.5s ease infinite;
}


.btn--disabled,
input[type=submit]:disabled,
button:disabled,
button[disabled] {
  color: $primary-40;
  border-color: $primary-20;
  background: $primary-20;
  cursor: default;
}

.btn--disabled:hover,
input[type=submit]:disabled:hover,
button:disabled:hover,
button[disabled]:hover {
  color: $neutral-70;
  border-color: $neutral-30;
  background: $neutral-30;
  cursor: default;
  box-shadow: none;
}

.btn--fullwidth {
  width: 100%;
}

.btn--confirm-checkbox-removal {
  text-align:right;
}


.btn--margin-bottom {
  margin-bottom: 16px;
}