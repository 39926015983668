/* @ICONNAV */
.iconnav {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 600px) {
  .iconnav {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .iconnav {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
  }
}

@media screen and (min-width: 1920px) {
  .iconnav {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.iconnav--margin-bottom {
  margin-bottom: 16px;
}

.iconnav .iconnav-tab {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  margin-right: 16px;
  text-decoration: none;
  color: $neutral-70;
  padding: 8px;
  border-radius: 4px;
  background: white;
  box-shadow: $box-shadow;
  border: 2px solid $primary-40;  
  text-align: center;
  position: relative;
}

.iconnav .iconnav-tab .app-icon {
  margin-bottom: 4px;
  display: block;
}

.iconnav-tab.iconnav-tab--active,
.iconnav .iconnav-tab:hover {
  background: white;
  border: 2px solid $primary-70;
  box-shadow: $box-shadow-selected;
}

.iconnav-tab.iconnav-tab--active .iconnav-tab--title{
  color: $neutral-100;
}

.iconnav-tab--title {
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: $primary-70;
  text-overflow: ellipsis;
  overflow: hidden; 
}

.iconnav-tab--type {
  position: absolute;
  top: 10px;
  left: 10px;
  border: 1px solid $neutral-30;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

@media screen and (min-width: 600px) {
  .iconnav-tab--title {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .iconnav .iconnav-tab {
    padding: 16px;
  }
  .iconnav-tab--title {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1920px) {
  .iconnav-tab--title {
    font-size: 16px;
    line-height: 20px;
  }
}