/* MAGIC BLINDS */
.device-type__magicblinds {

  div:last-child {
    border: none;
  }

  .magicswitch-blinds__row {
    display: grid;
    grid-template-columns: 60px auto 40px;
    grid-gap: 16px;
    border-bottom: 1px solid $primary-40;
    padding: 16px;
  }


  .blinds-row__icon {
  }

  .blinds-row__description {
  }

  .blinds-row__state {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
  }

  .blinds-row__switch {
  }

}