.room-card {
  position: relative;
  display: block;
  border: 1px solid $primary-40;
  background: $neutral-0;
  box-shadow: $box-shadow;
  border-radius: 4px;
  margin-bottom: 32px;
  overflow: hidden;
}

/* HEADER */
.room-card__header {
  padding: 8px;
  display: grid;
  grid-template-columns: auto 40px;
  padding: 8px;
  border-bottom: 1px solid $primary-40;
}

.room-card__title {
  font-weight: 600;
  font-size: 24px;
  color: #322859;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  padding: 8px;
}

.room-card__title-button {
  display: block;
  width: 40px;
  height: 40px;
  background: $button-primary-background;
  border: 1px solid $primary-90;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
}

.room-card__title-button:hover {
  background: $button-primary-background-hover;
}


.room-card__body {
  padding: 8px;
}


.room-card__devices {
  padding: 8px;
}



.room-card__body {
  border-top: 1px solid $primary-40;
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 1200px) {
  .room-card__body {
    grid-template-columns: 360px 2fr;
  }
  .room-card__room-details {
    border-right: 1px solid $primary-40;
  }
}

.room-card__room-stats {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 400px) {
  .room-card__room-stats {
    grid-template-columns: 1fr 1fr;
    height: 110px;
  }
}

.room-card__stat {
  border-bottom: 1px solid $primary-40;
  padding: 16px;
}

@media screen and (min-width: 400px) {
  .room-card__stat {
    border-right: 1px solid $primary-40;
  }
  .room-card__stat:last-child {
    border-right: none;
  }
}

.room-card__section-title {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: 700;
}

.room-card__section-title--margin-bottom {
  margin-bottom: 8px;
}

.room-card__stat-value {
  color: $neutral-100;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 24px 0 16px;
  text-align: center;
}

.room-card__image {
  display: grid;
  grid-template-columns: 1fr;
  height: 200px;
  border-bottom: 1px solid $primary-40;
  padding: 16px;
  text-decoration: none;
}

.room-card__image-light {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  height: 40px;
}

.room-card__image-image {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
}

.room-card__light-wrapper {
  border: 1px solid $neutral-30;
  border-radius: 4px;
  width: 200px;
  height: 8px;
}

.room-card__light-value {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: $neutral-100;
};

.room-card__light-stat {
  height: 6px;
  width: 0%;
  background: $secondary-50;
}

@media screen and (min-width: 1200px) {
  .room-card__image {
    height: calc(100% - 110px);
    border-bottom: 0;
  }
}

.room-card__room-devices {
  padding: 16px 16px 5px;
  overflow: hidden;
  border-bottom: 1px solid $neutral-30;
}

.room-card__devices-count {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 8px;
}

.room-card__devices-list::after {
  content: "";
  clear: both;
  display: table;
}

.room-card__devices-device {
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 70px;
  border-radius: 4px;
  margin: 0 8px 8px 0;
  float: left;
  border: 1px solid $primary-90;
  cursor: default;
  background: $button-primary-background;
  cursor: pointer;
}

.room-card__devices-device:hover {
  background: $button-primary-background-hover;
}

.room-card__devices-device--add {
  background: $neutral-0;
  border: 2px dashed $primary-40;
}

.room-card__devices-device--magic-panel {
  line-height: 80px;
} 

.room-card__devices-io-items {
  padding: 16px;
}

.room-card__devices-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

@media screen and (min-width: 768px) {
  .room-card__devices-buttons {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media screen and (min-width: 1400px) {
  .room-card__devices-buttons {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}



.device-io-item {
  border: 2px solid $primary-20;
  border-radius: 8px;
  padding: 8px;
  min-width: 160px;
  transition: 200ms;
}

.device-io-item.device-io-item--off,
.device-io-item.device-io-item--on {
  cursor: pointer;
}

.device-io-item.device-io-item--off:hover,
.device-io-item.device-io-item--on:hover {
  border-color: $primary-70;
  box-shadow: $box-shadow;
}

.device-io-item.device-io-item--on {
  border-color: $neutral-100;
}

.device-io-item.device-io-item--off {
  border-color: $neutral-70;
}

.device-io-item__state-text {
  text-transform: uppercase;
  color: $primary-20;
  font-weight: 700;
}

.device-io-item__title {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: $primary-20;
}

.device-io-item__icon {
  text-align: center;
  padding: 16px;
}

.device-io-item.device-io-item--on .device-io-item__title,
.device-io-item.device-io-item--on .device-io-item__state-text {
  color: $neutral-100;
}

.device-io-item.device-io-item--off .device-io-item__title,
.device-io-item.device-io-item--off .device-io-item__state-text {
  color: $neutral-70;
}

.device-io-item__device-label {
  padding: 16px 0;
  text-align: center;
}
